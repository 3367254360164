var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container Staffing" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.titles } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新增"],
                                expression: "['新增']",
                              },
                            ],
                            staticClass: "new-input-class",
                            attrs: { type: "success" },
                            on: { click: _vm.openDialogFun },
                          },
                          [_vm._v("新增")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      "header-cell-style": {
                        background: "#F8F9FB",
                        color: "#222222",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "professional_level",
                        label: "职级",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "basic_salary",
                        label: "职级底薪",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(scope.row.basic_salary) +
                                    "\n                    "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        56539756
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "individual_sales",
                        label: "个人业绩指标（万元）",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.moneyFun(
                                        scope.row.individual_sales,
                                        10000,
                                        1,
                                        2
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        268218033
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "individual_kpi_ratio",
                        label: "个人业绩提点",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.moneyFun(
                                        scope.row.individual_kpi_ratio,
                                        100,
                                        2,
                                        2
                                      )
                                    ) +
                                    "% "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2482300243
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "is_leader",
                        label: "是否为主管",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.is_leader == 1
                                  ? _c("span", [_vm._v("是")])
                                  : _c("span", [_vm._v("否")]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        677874138
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "group_sales",
                        label: "团队业绩指标（万元）",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.is_leader == 1
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.moneyFun(
                                            scope.row.group_sales,
                                            10000,
                                            1,
                                            2
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1071106217
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "group_sales",
                        label: "团队业绩提点",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.is_leader == 1
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.moneyFun(
                                            scope.row.group_kpi_ratio,
                                            100,
                                            2,
                                            2
                                          )
                                        ) + "%"
                                      ),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        388656384
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "create_time",
                        label: "创建时间",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "author_name",
                        label: "创建人",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "操作" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["新增"],
                                        expression: "['新增']",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClick(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["删除"],
                                        expression: "['删除']",
                                      },
                                    ],
                                    staticClass: "red-button-class",
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteHandleFun(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["修改记录"],
                                        expression: "['修改记录']",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.tableHistoryFun(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("修改记录")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1346085877
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      width: "700px",
                      title: _vm.dialogTitle,
                      visible: _vm.dialogFormVisible,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogFormVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.form, rules: _vm.rules },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "professional_level",
                              label: "选择职级：",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  disabled: _vm.disabledEdit,
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.form.professional_level,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "professional_level",
                                      $$v
                                    )
                                  },
                                  expression: "form.professional_level",
                                },
                              },
                              _vm._l(_vm.levelOptions, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "basic_salary",
                              label: "职级底薪：",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "200px",
                                "margin-right": "10px",
                              },
                              attrs: { autocomplete: "off" },
                              on: {
                                blur: function ($event) {
                                  return _vm.onVaildNumberFun("basic_salary")
                                },
                              },
                              model: {
                                value: _vm.form.basic_salary,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "basic_salary", $$v)
                                },
                                expression: "form.basic_salary",
                              },
                            }),
                            _vm._v("元\n                    "),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "individual_sales",
                              label: "个人业绩指标：",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "200px",
                                "margin-right": "10px",
                              },
                              attrs: { autocomplete: "off" },
                              on: {
                                blur: function ($event) {
                                  return _vm.onVaildNumberFun(
                                    "individual_sales"
                                  )
                                },
                              },
                              model: {
                                value: _vm.form.individual_sales,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "individual_sales", $$v)
                                },
                                expression: "form.individual_sales",
                              },
                            }),
                            _vm._v("万元\n                    "),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "individual_kpi_ratio",
                              label: "个人业绩提点：",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "200px",
                                "margin-right": "10px",
                              },
                              attrs: { autocomplete: "off" },
                              on: {
                                blur: function ($event) {
                                  return _vm.onVaildNumberFun(
                                    "individual_kpi_ratio"
                                  )
                                },
                              },
                              model: {
                                value: _vm.form.individual_kpi_ratio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "individual_kpi_ratio",
                                    $$v
                                  )
                                },
                                expression: "form.individual_kpi_ratio",
                              },
                            }),
                            _vm._v("%\n                    "),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "is_leader",
                              label: "是否为主管：",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { change: _vm.changeIsLeaderFun },
                                model: {
                                  value: _vm.form.is_leader,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_leader", $$v)
                                  },
                                  expression: "form.is_leader",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.form.is_leader == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "group_sales",
                                  label: "团队业绩指标：",
                                  "label-width": _vm.formLabelWidth,
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "200px",
                                    "margin-right": "10px",
                                  },
                                  attrs: {
                                    placeholder: "不包含个人业绩",
                                    autocomplete: "off",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.onVaildNumberFun("group_sales")
                                    },
                                  },
                                  model: {
                                    value: _vm.form.group_sales,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "group_sales", $$v)
                                    },
                                    expression: "form.group_sales",
                                  },
                                }),
                                _vm._v("万元\n                    "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.is_leader == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "group_kpi_ratio",
                                  label: "团队业绩提点：",
                                  "label-width": _vm.formLabelWidth,
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "200px",
                                    "margin-right": "10px",
                                  },
                                  attrs: {
                                    placeholder: "团队业绩提点",
                                    autocomplete: "off",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.onVaildNumberFun(
                                        "group_kpi_ratio"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.group_kpi_ratio,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "group_kpi_ratio", $$v)
                                    },
                                    expression: "form.group_kpi_ratio",
                                  },
                                }),
                                _vm._v("%\n                    "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogFormVisible = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.submitLoading,
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm("form")
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      width: "1000px",
                      title: "修改记录",
                      visible: _vm.dialogTableVisible,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogTableVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.gridUpdateData,
                          border: "",
                          "header-cell-style": {
                            background: "#F8F9FB",
                            color: "#222222",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "original_individual_sales",
                            label: "原个人业绩指标（万元）",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.moneyFun(
                                            scope.row.original_individual_sales,
                                            10000,
                                            1,
                                            2
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1906240919
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "now_individual_sales",
                            label: "修改后个人业绩指标（万元）",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.moneyFun(
                                            scope.row.now_individual_sales,
                                            10000,
                                            1,
                                            2
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4168341400
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "original_individual_kpi_ratio",
                            label: "原个人业绩提点（%）",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.moneyFun(
                                            scope.row
                                              .original_individual_kpi_ratio,
                                            100,
                                            2,
                                            2
                                          )
                                        ) +
                                        "% "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1141941621
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "now_individual_kpi_ratio",
                            label: "修改后个人业绩提点（%）",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.moneyFun(
                                            scope.row.now_individual_kpi_ratio,
                                            100,
                                            2,
                                            2
                                          )
                                        ) +
                                        "% "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1565150938
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "original_group_sales",
                            label: "原团队业绩指标（万元）",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.original_is_leader == 1
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.moneyFun(
                                                scope.row.original_group_sales,
                                                10000,
                                                1,
                                                2
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", [_vm._v("-")]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            873689513
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "now_group_sales",
                            label: "修改后团队业绩指标（万元）",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.now_is_leader == 1
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.moneyFun(
                                                scope.row.now_group_sales,
                                                10000,
                                                1,
                                                2
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", [_vm._v("-")]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4032543785
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "original_group_kpi_ratio",
                            label: "原团队业绩提点（%）",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.original_is_leader == 1
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.moneyFun(
                                                  scope.row
                                                    .original_group_kpi_ratio,
                                                  100,
                                                  2,
                                                  2
                                                )
                                              ) +
                                              "%\n                            "
                                          ),
                                        ])
                                      : _c("span", [_vm._v("-")]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3777452491
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "now_group_kpi_ratio",
                            label: "修改后团队业绩提点（%）",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.now_is_leader == 1
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.moneyFun(
                                                  scope.row.now_group_kpi_ratio,
                                                  100,
                                                  2,
                                                  2
                                                )
                                              ) +
                                              "% "
                                          ),
                                        ])
                                      : _c("span", [_vm._v("-")]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2851040267
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "update_time",
                            label: "修改时间",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "author_name",
                            label: "修改人",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }