import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { getConfigurableProfessionalLevelList, getSalesKpiConfigList, editSalesKpiConfig, removeSalesKpiConfig, getSalesKpiConfigInfo, getSalesKpiConfigDiffLogList } from '@/api/personalAnalysis';
export default {
  name: 'keyPerformanceConfig',
  data: function data() {
    return {
      // 系统教程
      course: this.$route.query.course,
      titles: '业绩指标配置',
      dialogTitle: '新增职级',
      tableData: [],
      // 修改记录表格数据
      gridUpdateData: [],
      dialogTableVisible: false,
      dialogFormVisible: false,
      // 职级编辑不允许修改
      disabledEdit: false,
      // 提交按钮loading
      submitLoading: false,
      form: {
        professional_level: '',
        individual_sales: '',
        basic_salary: '',
        //职级底薪
        group_sales: 0,
        is_leader: 0,
        individual_kpi_ratio: '',
        group_kpi_ratio: ''
      },
      rules: {
        professional_level: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        individual_sales: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        // 职级底薪
        basic_salary: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        group_sales: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        is_leader: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        individual_kpi_ratio: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        group_kpi_ratio: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }]
      },
      formLabelWidth: '150px',
      // 职级下拉列表
      levelOptions: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // 列表数据加载
    this.getListFun();
  },
  components: {
    Head: Head
  },
  methods: {
    getListFun: function getListFun() {
      var _this = this;
      getSalesKpiConfigList().then(function (res) {
        _this.tableData = res.data;
      });
    },
    // 编辑事件
    handleClick: function handleClick(row) {
      var _this2 = this;
      this.dialogTitle = '编辑职级';
      this.dialogFormVisible = true;
      this.disabledEdit = true;
      this.$nextTick(function () {
        _this2.$refs['form'].clearValidate();
      });
      // this.getConfigurableProfessionalLevelListFun('edit');
      // 获取数据详情
      this.getDataInfoFun(row.id);
    },
    // 新增事件
    openDialogFun: function openDialogFun() {
      var _this3 = this;
      this.dialogTitle = '新增职级';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['form'].clearValidate();
      });
      this.disabledEdit = false;
      this.form = {
        professional_level: '',
        individual_sales: '',
        basic_salary: '',
        //职级底薪
        group_sales: 0,
        is_leader: 0,
        individual_kpi_ratio: '',
        group_kpi_ratio: ''
      };
      this.getConfigurableProfessionalLevelListFun();
    },
    // 详情事件
    getDataInfoFun: function getDataInfoFun(id) {
      var _this4 = this;
      this.submitLoading = true;
      // 调用详情接口以后loading置为false
      getSalesKpiConfigInfo({
        id: id
      }).then(function (res) {
        var data = res.data;
        _this4.form = {
          id: data.id,
          professional_level: data.professional_level,
          individual_sales: _this4.moneyFun(data.individual_sales, 10000, 1, 2),
          // 职级底薪
          basic_salary: data.basic_salary,
          group_sales: _this4.moneyFun(data.group_sales, 10000, 1, 2),
          is_leader: data.is_leader,
          individual_kpi_ratio: _this4.moneyFun(data.individual_kpi_ratio, 100, 2, 2),
          group_kpi_ratio: _this4.moneyFun(data.group_kpi_ratio, 100, 2, 2)
        };
      }).finally(function () {
        _this4.submitLoading = false;
      });
    },
    // 表单保存
    submitForm: function submitForm(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.submitLoading = true;
          var params = {
            id: _this5.form.id,
            professional_level: _this5.form.professional_level,
            individual_sales: _this5.moneyFun(_this5.form.individual_sales, 10000, 2, 2),
            // 职级底薪
            basic_salary: Number(_this5.form.basic_salary),
            group_sales: _this5.moneyFun(_this5.form.group_sales, 10000, 2, 2),
            is_leader: _this5.form.is_leader,
            individual_kpi_ratio: _this5.moneyFun(_this5.form.individual_kpi_ratio, 100, 1, 4),
            group_kpi_ratio: _this5.moneyFun(_this5.form.group_kpi_ratio, 100, 1, 4)
          };
          // 保存的时候加清理数据 不是主管 清空团队相关提点和指标数据
          if (_this5.form.is_leader != 1) {
            params.group_sales = 0;
            params.group_kpi_ratio = 0;
          }
          editSalesKpiConfig(_this5.deleteEmptyProperty(params)).then(function (res) {
            _this5.getListFun();
            var str = '新增成功!';
            if (_this5.form.id) {
              str = '编辑成功!';
            }
            _this5.$message({
              type: 'success',
              message: str
            });
            _this5.dialogFormVisible = false;
          }).finally(function () {
            _this5.submitLoading = false;
          });
        }
      });
    },
    // 删除事件
    deleteHandleFun: function deleteHandleFun(id) {
      var _this6 = this;
      this.$confirm('职级数据删除后无法恢复审核，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        removeSalesKpiConfig({
          id: id
        }).then(function (res) {
          _this6.getListFun();
          _this6.$message({
            type: 'success',
            message: '删除成功!'
          });
        });
      }).catch(function () {});
    },
    // 修改记录
    tableHistoryFun: function tableHistoryFun(id) {
      var _this7 = this;
      this.dialogTableVisible = true;
      // 调用接口 给gridUpdateData赋值
      this.gridUpdateData = [];
      getSalesKpiConfigDiffLogList({
        id: id
      }).then(function (res) {
        _this7.gridUpdateData = res.data;
      });
    },
    // 校验数据格式
    onVaildNumberFun: function onVaildNumberFun(index) {
      var val = 0;
      val = this.form[index];
      // 最多两位小数
      var reg = /^(([1-9]{1}\d*)|^(0{1}))(\.\d{1,2})?$/;
      if (!reg.test(val)) {
        this.$message.error('数值支持两位小数');
        this.form[index] = '';
      }
    },
    // 获取可配置职级列表
    getConfigurableProfessionalLevelListFun: function getConfigurableProfessionalLevelListFun() {
      var _this8 = this;
      getConfigurableProfessionalLevelList().then(function (res) {
        _this8.levelOptions = res.data;
      });
    },
    changeIsLeaderFun: function changeIsLeaderFun(val) {
      if (val == 1) {
        this.$set(this.form, 'group_sales', '');
        this.$set(this.form, 'group_kpi_ratio', '');
      } else {
        this.$set(this.form, 'group_sales', '0');
        this.$set(this.form, 'group_kpi_ratio', '0');
      }
    },
    // 单位转换/10000
    moneyFun: function moneyFun(money, index) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      var length = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 2;
      if (type == 1) {
        var data = (Number(money) / index).toFixed(length);
        return data;
      } else {
        var _data = (Number(money) * index).toFixed(length);
        return _data;
      }
    }
  }
};